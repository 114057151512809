import axios from "axios";
import { getToken } from "./auth";

//  export const baseURL = "https://royal-backend.insignio.tech";
// export const baseURL = "http://localhost:8000";
  export const baseURL = "https://sd-backend.insignio.tech";


const API = axios.create({
  baseURL: baseURL + "/api",
});

API.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { API };
